@import "./styles/colors.css";

/* LOGIN */
* {
  box-sizing: border-box;
}
.leaflet-container {
  width: 100%;
  }

#root {
  height: 99%;
  
}

#main {
  height: 100%;
  max-height: 100%;
}

html {
  height: 100%;
  max-height: 100%;
  background-color: var(--color-bg-lower);
}

.full-height {
  height: 98%;
}

.card-body {
  background-color: inherit;
}

.card {
  background-color: inherit;
}

.App {
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.form-container {
  border: 1px solid aqua;
  border-radius: 20px;
}

.caption {
  font-size: 1.5rem;
  margin: 15px;
  padding: 5px;
}

.login-form {
  padding: 20px;
  margin: 50px;
}

.form-label {
  font-size: 1rem;
  padding: 1px;
  margin: 1px;
}

.form-field {
  padding: 5px;
  width: 300px;
  border: 1px solid var(--color-accent);
  border-radius: 10px;
}

.form-btn {
  float: right;
  width: 100px;
  padding: 5px;
  margin: 10px;
  border: 1px solid aqua;
  border-radius: 10px;
}

.form-btn:hover {
  cursor: pointer;
}

.user-info {
  text-align: center;
  font-size: 2rem;
}

.show {
  display: block;
}

.hide {
  display: none;
}


.language-select {
  float: right;
  /* margin-top: -35px; */
}

.badge {

  margin: 2px;
}

@media only screen and (min-width:320px) {
  ::-webkit-scrollbar {
    width: 0em;
  }

  ::-webkit-scrollbar-track {
    background-color: transparent;
  }

  ::-webkit-scrollbar-thumb {
    background-color: var(--color-accent-dark);
    border-radius: 20px;
    border: 6px solid transparent;
    background-clip: content-box;
  }

  body {
    /* color: #fff; */
    height: 99vh;

  }
}

@media (min-width:481px) {
  ::-webkit-scrollbar {
    width: 0em;
  }

  ::-webkit-scrollbar-track {
    background-color: transparent;
  }

  ::-webkit-scrollbar-thumb {
    background-color: var(--color-accent-dark);
    border-radius: 20px;
    border: 6px solid transparent;
    background-clip: content-box;
  }

  body {
    /* color: #fff; */
    height: 99vh;

  }
}

@media (min-width:600px) {
  ::-webkit-scrollbar {
    width: 1em;
  }

  ::-webkit-scrollbar-track {
    background-color: transparent;
  }

  ::-webkit-scrollbar-thumb {
    background-color: var(--color-accent-dark);
    border-radius: 20px;
    border: 6px solid transparent;
    background-clip: content-box;
  }

  body {
    /* color: #fff; */
    height: 99vh;
  }
}



.form-control:focus {
  border-color: var(--color-accent);
  box-shadow: 0 0 0 0.2rem var(--color-border-select);
}




@media print {

  html,
  body {
    height: visible !important;
    -webkit-print-color-adjust: exact;
  }
}

@media print {
  .page-break {
    display: block;
  }


  .calendar {
    overflow: visible !important;
    display: block;
    height: fit-content !important;
  }

  .calendar-cell {
    /* overflow: visible!important; */
    width: 30px !important;
    min-width: 30px !important;
    max-width: 30px !important;

  }

  .calendar-header {
    overflow: visible !important;
    width: 100% !important;
    height: fit-content;
  }
  .calendar-row-header {
    overflow: visible !important;
    width: 30px !important;
    min-width: 30px !important;
    max-width: 30px !important;
  }
  .calendar-content {
    overflow: visible !important;
    width: 100% !important;
  }
  .date-cell-text {
    visibility: visible !important;
  }
}

.date-cell-text {
  visibility: hidden;
}

.calendar {
  max-height: 90vh;
}

.calendar-cell {
  /* overflow: visible!important; */
  min-width: 100px;
  max-width: 100px;
}


.ripple {
  animation: ripple 0.8s linear infinite;
}

@keyframes ripple {
  0% {
    box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.2),
      0 0 0 2px rgba(0, 0, 0, 0.2),
      0 0 0 6px rgba(0, 0, 0, 0.2);
  }

  100% {
    box-shadow: 0 0 0 2px rgba(0, 0, 0, 0.2),
      0 0 0 6px rgba(0, 0, 0, 0.2),
      0 0 0 12px rgba(0, 0, 0, 0);
  }
}

.calendar-header {
  -ms-scrollbar-style: none !important;
  scrollbar-width: none !important;
}

.calendar-header::-webkit-scrollbar {
  display: none;
  /* Safari and Chrome */
}

.calendar-row-header {
  -ms-scrollbar-style: none !important;
  scrollbar-width: none !important;
}

.calendar-row-header::-webkit-scrollbar {
  display: none;
  /* Safari and Chrome */
}