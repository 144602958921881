:root {
    --color-bg-lower:#f3f3f4;
    --color-bg-low:#eaeaea;
    --color-bg: #f3f8fe;
    --color-bg-high:#ddebff;
    --color-bg-higher:rgb(59, 105, 255);
    --color-bg-hover: #17193f;
    --color-bg-selected:#272727;
    --color-border-selected:#03234d;

    --color-primary-border:#002c6aff;
    --color-border-select: #002c6aff;

    --color-primary: #002c6aff;
    
    --color-secondary: #002c6aff;
    --color-tertiary: #002c6aff;
    --forground-item: #000000;
    --color-accent: #002c6aff;
    --color-accent-light: #bad7ff;
    --color-accent-light-trans: #002c6aff;

    --color-accent-dark: #353535;
    --font-color: #FFF;
    --font-color-darker: #FFF;
  }
  
  .theme-dark {
    --color-bg-low: #dfe0e2;
    --color-bg: #fff;


    --color-primary: #ffffff;
    --color-secondary: #ffffff;
    --color-tertiary: #1eff00;
    --color-accent: #ffffff;
    --font-color: #ffffff;
  }
  
